import { APP_INITIALIZER, inject } from '@angular/core';
import { ActivityDto, ActivityEntityName } from '@nest/domain/entities-metadata/dtos';
import { EntityMetadataService, MultilineTextPathRendererComponent } from '@nest/uikit/entity-presentation/ui';
import { autoPathsFor } from '@nest/utils/shared';
import { EntityActivitiesWidgetComponent } from './entity-activities-widget.component';

export const ActivitiesWidgetName = 'EntityActivities';

export function provideActivitiesEntities() {
	return [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: () => {
				let entityMetadata = inject(EntityMetadataService);
				// let activityDataAccess = inject(ActivityDataAccessService);

				return () => {
					// entityMetadata.registerEntity({
					// 	entityName: ActivityEntityName,
					// 	dataAccess: activityDataAccess as TODO,
					// 	defaultElements: fieldsToElements(autoPathsFor<ActivityDto>()(['id']))
					// });

					entityMetadata.registerEntityPathRenderer(ActivityEntityName, autoPathsFor<ActivityDto>()(['message']), MultilineTextPathRendererComponent);

					entityMetadata.registerEntityWidget({
						widgetName: ActivitiesWidgetName,
						label: 'Aktivity',
						displayType: 'block',
						widgetComponentType: EntityActivitiesWidgetComponent
					});
				};
			}
		}
	];
}

export function withActivityWidget() {
	return {
		label: 'Události',
		name: 'activities',
		elements: [
			{
				widgetName: ActivitiesWidgetName
			}
		]
	};
}
