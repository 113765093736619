import { ChangeDetectionStrategy, Component, effect, inject, input, untracked } from '@angular/core';
import { QueryOrder } from '@nest/common/core/shared';
import { ActivityDto, ActivityEntityName } from '@nest/domain/entities-metadata/dtos';
import { EntityGridComponent, provideEntityListStore } from '@nest/uikit/entity-grid/ui';
import { Entity, EntityElement, EntityMetadataService, fieldsToElements } from '@nest/uikit/entity-presentation/ui';
import { store, useEntityList } from '@nest/utils/frontend/store';
import { type } from '@nest/utils/shared';

const EntityActivitiesStore = store(() =>
	useEntityList({
		key: 'activities-widget',
		entityType: type<ActivityDto>(),
		entityName: ActivityEntityName,
		views: {
			default: {
				label: 'Základní',
				name: 'default',
				elements: fieldsToElements(['createdAt', 'identity.name', 'message'])
			}
		}
	})
);

@Component({
	standalone: true,
	template: `
		@if (entity()) {
			<lib-entity-grid></lib-entity-grid>
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	viewProviders: [provideEntityListStore(EntityActivitiesStore)],
	imports: [EntityGridComponent]
})
export class EntityActivitiesWidgetComponent<E extends Entity> {
	entityMetadatas = inject(EntityMetadataService);
	store = inject(EntityActivitiesStore);

	entityName = input.required<string>();
	element = input.required<EntityElement>();
	entity = input.required<E>();

	constructor() {
		effect(() => {
			this.entityName();
			this.entity();

			untracked(() => {
				this.store.setBaseFilter({
					modelName: this.entityName(),
					modelId: this.entity().id
				});

				this.store.setOrder({
					id: QueryOrder.DESC
				});

				this.store.refresh();
			});
		});
	}
}
