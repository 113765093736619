import { inject } from '@angular/core';
import { AuthService, injectRpcClient } from '@nest/common/core/frontend';
import { activitiesRouter } from '@nest/domain/activities/shared';
import { injectToaster } from '@nest/uikit/toaster/ui';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { filter, pipe, switchMap } from 'rxjs';

const initialState = {
	unreadNotificationCount: 0
};

export const NotificationsStore = signalStore(
	{ providedIn: 'root' },
	withState(initialState),
	withMethods(store => {
		const client = injectRpcClient(activitiesRouter);
		const toaster = injectToaster();
		const auth = inject(AuthService);

		const refreshUnreadNotificationCount = rxMethod<void>(
			pipe(
				filter(() => auth.loggedIn()),
				switchMap(async () => {
					const unreadNotificationCount = await client.notifications.getUnreadNotificationsCount();
					patchState(store, { unreadNotificationCount });
				})
			)
		);

		return {
			refreshUnreadNotificationCount,
			startCheckingNewNotifications: rxMethod(
				pipe(
					filter(() => auth.loggedIn()),
					switchMap(async () => {
						const newNotifications = await client.notifications.getNewNotifications();

						if (newNotifications.length > 0) {
							refreshUnreadNotificationCount();

							patchState(store, {
								unreadNotificationCount: store.unreadNotificationCount() + newNotifications.length
							});
							for (const notification of newNotifications) {
								toaster.message('Nové upozornění', {
									description: notification.message,
									position: 'top-right'
								});
							}
						}
					})
				)
			)
		};
	})
);
